"use client";

import { useEffect, useRef, useState } from "react";
import type { LottiePlayer } from "lottie-web";
import Box from "@mui/material/Box";
const LogoLoading = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);
  useEffect(() => {
    import("lottie-web").then(Lottie => setLottie(Lottie.default));
  }, []);
  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/assets/loading.json"
      });
      return () => animation.destroy();
    }
  }, [lottie]);
  return <Box sx={{
    height: {
      xs: "calc(100dvh - 480px)",
      md: "calc(100dvh - 484px - 144px)"
    },
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }} data-sentry-element="Box" data-sentry-component="LogoLoading" data-sentry-source-file="index.tsx">
      <Box sx={{
      width: "100dvw",
      height: "100dvh",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      zIndex: 1111,
      opacity: 0.8
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Box sx={{
        maxWidth: "500px",
        marginTop: "-300px"
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <div ref={ref} />
        </Box>
      </Box>
    </Box>;
};
export default LogoLoading;